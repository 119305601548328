<template>
  <div class="video-control" v-if="player">
    <div class="control">
      <iconPlay class="svg-stroke-white" @click="player.play()" v-if="!isPlaying"></iconPlay>
      <iconPause class="svg-stroke-white" @click="player.pause()" v-if="isPlaying"></iconPause>
    </div>

    <div class="timeline" @click="onTimelineClick">
      <div class="bg"></div>
      <div class="line" :style="{height: videoProgress + '%'}"></div>
    </div>

    <div class="audio">
      <iconAudioOn class="svg-stroke-white" @click="player.muted = true" v-if="!isMute"></iconAudioOn>
      <iconAudioOff class="svg-stroke-white" @click="player.muted = false" v-if="isMute"></iconAudioOff>
    </div>
  </div>
</template>

<script>
import iconPlay from '@/assets/svg/play.svg'
import iconPause from '@/assets/svg/pause.svg'
import iconAudioOn from '@/assets/svg/audio-on.svg'
import iconAudioOff from '@/assets/svg/audio-off.svg'

import { RAF } from '@monogrid/js-utils'

export default {
  name: 'VideoControl',

  components: {
    iconPlay, iconPause, iconAudioOff, iconAudioOn
  },

  data () {
    return {
      isPlaying: false,
      isMute: false,
      wasPlaying: false,
      productShow: [],
      videoProgress: 0,
      currentTime: 0,
      duration: 0
    }
  },

  props: {
    player: {
      type: Object
    }
  },

  mounted () {
    this.checkRoute()

    this.$events.on('onPlayVideo', () => {
      this.player.play()
    })
  },

  methods: {
    onTimelineClick (e) {
      // console.log(e.offsetY, e.target.clientHeight)
      const perc = e.offsetY / e.target.clientHeight

      if (this.player) {
        const newTime = perc * this.player.duration
        console.log(newTime)
        this.player.currentTime = newTime
      }
    },
    checkRoute () {
      if (this.$route.name === 'Video' && !this.$route.params.product) {
        RAF.add(this.update)
        if (this.player) {
          this.player.play()
        }
      } else if (this.$route.name !== 'Video') {
        RAF.remove(this.update)
        if (this.player) {
          this.player.stop()
        }
      }
    },
    update () {
      if (this.player) {
        this.isPlaying = this.player && this.player.playing
        this.isMute = this.player && this.player.muted

        if (this.isPlaying) {
          this.videoProgress = (this.player.currentTime / this.player.duration) * 100
          this.currentTime = this.player.currentTime
        }

        if (this.duration === 0 || this.duration !== this.player.duration) {
          this.duration = this.player.duration
        }
      }
    }
  },

  watch: {
    $route (newValue, oldValue) {
      this.checkRoute(newValue, oldValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.video-control {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  width: 60px;
  height: 100%;
  background-color: $c-cream;
}

.timeline {
  height: calc(100% - 160px);
  position: relative;

  .bg {
    height: 100%;
    position: absolute;
    width: 2px;
    background-color: #555;
    left: 30px;
  }

  .line {
    position: absolute;
    width: 2px;
    background-color: #fff;
    left: 30px;
  }
}

.audio {
  position: relative;
  bottom: 0;

  svg {
    position: absolute;
  }
}
</style>
