<template>
  <div v-if="content" class="macro-hotspot-content">
    <div
      @click="$emit('onHotspotClick')"
      :style="{
        top: position.split(',')[1] + '%',
        left: position.split(',')[0] + '%'
      }"
      class="hotspot-container first"
    >
      <div class="hotspot">
        <div class="outline"></div>
        <div class="pulse"></div>
        <div class="inner"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  },

  computed: {
    position () {
      return this.$root.platform.mobile && this.content.positionMobile ? this.content.positionMobile : this.content.position
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
