<template>
  <div class="audio-player pa-8">
    <AudioOn v-show="playing" @click="togglePlayback" class="svg-stroke-white pointer" />
    <AudioOff v-show="!playing" @click="togglePlayback" class="svg-stroke-white pointer" />
  </div>
</template>

<script>
import VueHowler from 'vue-howler'

import AudioOn from '@/assets/svg/sound-full.svg'
import AudioOff from '@/assets/svg/sound-off.svg'

export default {
  mixins: [
    VueHowler
  ],

  components: {
    AudioOn,
    AudioOff
  }
}
</script>

<style lang="scss" scoped>
  .audio-player {
    position: absolute;
    bottom: 0%;
    right: 0%;
    z-index: 2;

    svg {
      width: 28px;
      height: 28px;
    }
  }
</style>
