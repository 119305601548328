<template>
  <div class="fullscreen transparent-black">
    <div class="absolute-center text-center">
      <h1 class="brushaff text-capitalize mb-6 mb-md-16 pb-2 pb-md-4">{{ $root.allTranslations[$route.params.language].endVideoTitleCopy }}</h1>
      <p>{{ $root.allTranslations[$route.params.language].endVideoMessageCopy }}</p>
      <RightArrowIcon class="svg-fill-white" />
    </div>
  </div>
</template>

<script>

import RightArrowIcon from '@/assets/svg/arrow-right.svg'

export default {
  components: {
    RightArrowIcon
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: 28px;
  height: 28px;
  transform: rotate(90deg) !important;
}
</style>
