<template>
  <div v-if="sequence" class="macro-hotspot-overlay">
    <transition name="fade-slide" appear>
      <div class="hotspot-title text-uppercase text-center">
        <h2 class="brushaff">
          <span
            v-for="(char,i) in sequence.content.HotspotCarousel[$route.params.language + 'HotspotTitle']"
            :style="{'animation-delay': (i * 0.05) + 's'}"
            :key="'char'+i">
            {{ (char === ' ') ? '&nbsp;' : char }}
          </span>
        </h2>
        <MarkdownBlock
          :inline="false"
          linkify
          tag="div"
          :text="sequence.content.HotspotCarousel[$route.params.language + 'HotspotSubTitle']"
        />
      </div>
    </transition>

    <transition name="fade-slide">
      <p :style="{'transition-delay': '0.5s'}" v-if="!sequence.opened" class="tips text-center">
        {{ $root.allTranslations[$route.params.language].hotspotTipCopy }}
      </p>
    </transition>

    <transition name="fade-slide">
      <div :style="{'transition-delay': '0.75s'}" class="overlay" v-if="sequence.opened && sequence.nextcontent && !sequence.showCarousel">
        <p
          v-if="sequence.nextcontent && sequence.content.HotspotCarousel.title !== 'Passata'"
          class="hotspot-button pointer mb-0"
          @click="$emit('onContinue')"
        >
          {{ sequence.content.HotspotCarousel[$route.params.language + 'NextMacroHotspotCopy'] }}
        </p>
      </div>
    </transition>

  </div>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'

export default {
  props: {
    sequence: {
      type: Object,
      required: true
    }
  },

  components: {
    MarkdownBlock
  }
}
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.hotspot-title {
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  width: 85%;
  margin: 0 auto;
  text-align: center;

}

.tips {
  position: fixed;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -65%);
  width: 85%;
  margin: 0 auto;
  text-align: center;
}

.hotspot-button {
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: $c-primary;
  box-shadow: $c-black-50 0 0 12px;
  width: 400px;
  padding: 8px 22px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.4s ease-in-out;

  @include breakpoint('sm-and-down') {
    max-width: 70%;
    padding: 6px 10px;
    font-size: 14px;
  }

  @include breakpoint('xs-only') {
    padding: 10px 10px 6px !important;
  }

  &:hover {
    background-color: $c-white;
    color: $c-primary;
  }
}

h2 span {
  // animation: jump 2s infinite;
}
</style>
