<template>
  <div class="video-selection">
    <div
      v-for="(hotspot, i) in $root.siteSetting.videoSelection"
      :key="i"
      @click="continueVideo(i)"
      :style="{top: getPosition(i).split(',')[1] + '%', left: getPosition(i).split(',')[0] + '%'}"
      :class="{'first': i === 0}"
      class="hotspot-container"
    >
      <div
        class="hotspot">
        <div class="outline"></div>
        <div class="pulse"></div>
        <div class="inner"></div>
      </div>
      <h3 class="brushaff text-uppercase">
        <span
          v-for="(char,i) in hotspot.content[$route.params.language + 'Title']"
          :style="{'animation-delay': (i * 0.01) + 's'}"
          :key="'char'+i">
          {{ (char === ' ') ? '&nbsp;' : char }}
        </span>
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getPosition (index) {
      return this.$root.siteSetting.videoSelection[index].positionMobile && this.$root.platform.mobile ? this.$root.siteSetting.videoSelection[index].positionMobile : this.$root.siteSetting.videoSelection[index].position
    },
    continueVideo (i) {
      if (i === 0) {
        this.$emit('onVideoSelected')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  word-wrap: none;
  white-space: nowrap;
}

.first span {
  animation: scale 2s infinite;

  @include breakpoint('xs-only') {
    animation: scalemobile 2s infinite;
  }
}
</style>
