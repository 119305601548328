<template>
  <div v-if="content" class="hotspot-carousel fullscreen transparent-black">

    <!-- Content carousel -->
    <v-container class="fill-height">
      <v-row align-content="center" class="fill-height">
        <v-col cols="12" class="d-flex flex-column justify-space-around">
          <!-- Top content title -->
          <h3 class="brushaff text-uppercase text-center mb-3 mb-md-6">
            {{ content.HotspotCarousel[$route.params.language + 'Title'] }}
          </h3>
          <v-row align="center" justify="center">
            <!-- Prev button -->
            <v-col cols="1" align="center" class="pa-0">
              <transition name="fade">
                <div
                  v-if="content.HotspotCarousel.hotspotSlides.length > 1 && currentSlideIndex > 0"
                  @click="currentSlideIndex--"
                  class="carousel-btn prev pointer d-flex align-center justify-center"
                >
                  <LeftArrowIcon class="svg-fill-white"/>
                </div>
              </transition>
            </v-col>

            <!-- Carousel data -->
            <v-col cols="12" md="10" align="center">
              <div
                v-for="(slide, i) in content.HotspotCarousel.hotspotSlides"
                :key="i"
                :class="{
                  'active': i == currentSlideIndex
                }"
                class="hotspot-carousel-slide"
              >
                <div
                  v-if="slide.video ||
                    slide[$route.params.language + 'DesktopVideo'] ||
                    slide[$route.params.language + 'MobileVideo']"
                >
                  <vue-plyr
                    ref="hotspotVideo"
                    :options="playerOptions"
                    class="hotspot-video mb-3 mb-md-6"
                  >
                    <video :src="'/media/' + getSlideVideo(slide)" crossorigin></video>
                  </vue-plyr>
                </div>
                <div v-if="slide.img || slide[$route.params.language + 'Img']">
                  <img :src="'/media/' + getSlideImage(slide)"
                    class="hotspot-img mb-3 mb-md-6"
                    :class="{'bottle' : content.HotspotCarousel.title === 'Passata' && currentSlideIndex === content.HotspotCarousel.hotspotSlides.length - 1}" />
                </div>
                <MarkdownBlock
                  v-if="slide[$route.params.language + 'Text']"
                  :inline="false"
                  linkify
                  tag="div"
                  :text="slide[$route.params.language + 'Text']"
                  class="slide-paragraph"
                />
                <!-- CTA under packaging slide on last carousel -->
                <div
                  v-show="content.HotspotCarousel.title === 'Passata' && currentSlideIndex === content.HotspotCarousel.hotspotSlides.length - 1"
                  @click="onCtaClick"
                  class="cta-button pointer"
                >
                  {{ content.HotspotCarousel[$route.params.language + 'NextMacroHotspotCopy'] }}
                </div>
              </div>
            </v-col>

            <!-- Next button -->
            <v-col cols="1" align="center" class="pa-0">
              <transition name="fade">
                <div
                  v-if="content.HotspotCarousel.hotspotSlides.length > 1 && currentSlideIndex !== content.HotspotCarousel.hotspotSlides.length - 1"
                  @click="currentSlideIndex++"
                  class="carousel-btn next pointer d-flex align-center justify-center"
                >
                  <RightArrowIcon class="svg-fill-white"/>
                </div>
              </transition>
            </v-col>

          </v-row>
          <!-- Close icon -->
          <div
            @click="onHotspotCarouselClose(content.HotspotCarousel.title)"
            class="carousel-btn close pointer d-flex align-center justify-center"
          >
            <CloseIcon class="svg-stroke-white"/>
          </div>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'

import CloseIcon from '@/assets/svg/cross-icon.svg'
import LeftArrowIcon from '@/assets/svg/arrow-left.svg'
import RightArrowIcon from '@/assets/svg/arrow-right.svg'

export default {
  components: {
    MarkdownBlock,
    CloseIcon,
    LeftArrowIcon,
    RightArrowIcon
  },

  props: {
    content: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      currentSlideIndex: 0,
      isPlayingVideo: false,
      playerOptions: {
        storage: { enabled: false, key: 'hotspotVideo' },
        fullscreen: { enabled: false },
        volume: 0.2,
        autoplay: false,
        controls: ['play', 'progress', 'current-time'],
        hideControls: false
      }
    }
  },

  methods: {
    getSlideVideo (slide) {
      return this.$root.platform.mobile
        ? slide[this.$route.params.language + 'MobileVideo'].split('/')[2]
        : slide[this.$route.params.language + 'DesktopVideo'].split('/')[2]
    },
    getSlideImage (slide) {
      return slide.img
        ? slide.img.split('/')[2]
        : slide[this.$route.params.language + 'Img'].split('/')[2]
    },
    onCtaClick () {
      const msg = { type: 'scrollToForm' }
      console.log('Mutti Video POST MESSAGE', msg)
      window.parent.postMessage(msg, '*')
    },
    onHotspotCarouselClose (hotspotTitle) {
      this.$emit('onCloseContinue', hotspotTitle)
      if (hotspotTitle === 'Instafactory' && this.isPlayingVideo) {
        this.$emit('onPlayPauseAudio')
      }
    }
  },

  watch: {
    currentSlideIndex: function (newVal) {
      if (this.$refs.hotspotVideo) {
        if (this.content.HotspotCarousel.hotspotSlides[newVal].video ||
          this.content.HotspotCarousel.hotspotSlides[newVal][this.$route.params.language + 'DesktopVideo'] ||
          this.content.HotspotCarousel.hotspotSlides[newVal][this.$route.params.language + 'MobileVideo']) {
          this.isPlayingVideo = true
          this.$refs.hotspotVideo[0].player.play()
        } else {
          this.isPlayingVideo = false
          this.$refs.hotspotVideo[0].player.pause()
        }
      }
    },
    isPlayingVideo: function () {
      this.$emit('onPlayPauseAudio')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .plyr--full-ui input[type=range] {
  color: $c-primary;
}

::v-deep .plyr--video .plyr__control.plyr__tab-focus,
::v-deep .plyr--video .plyr__control:hover,
::v-deep .plyr--video .plyr__control[aria-expanded=true] {
  background-color: $c-primary;
}

.hotspot-carousel {
  z-index: 4;
}

.hotspot-carousel-slide {
  &:not(.active) {
    display: none;
  }

  &.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    animation: fadeIn 0.4s ease-in-out;

    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
  }
}

.carousel-btn {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border: 1px solid $c-white;
  border-radius: 50%;
  background-color: $c-white-25;
  transition: all 0.4s ease-in-out;

  @include breakpoint('sm-and-down') {
    width: 40px;
    height: 40px;
  }

  @include breakpoint('xs-only') {
    width: 30px;
    height: 30px;
  }

  &:hover {
    transform: scale(1.08);
  }

  &.close {
    width: 30px;
    height: 30px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.next {
  transform: translateY(-50%);

  @include breakpoint('sm-and-down') {
    position: fixed;
    right: 10px;
    top: 70%;
    z-index: 100;
    margin-left: -50px;
  }

  @include breakpoint('xs-only') {
    top: 40%;
  }

  &:hover {
    transform: translateY(-50%) scale(1.08);
  }
}

.prev {
  transform: translateY(-50%);
  @include breakpoint('sm-and-down') {
    position: fixed;
    left: 10px;
    top: 70%;
    z-index: 100;
    margin-right: -50px;
  }

  @include breakpoint('xs-only') {
    top: 40%;
  }

  &:hover {
    transform: translateY(-50%) scale(1.08);
  }
}

.hotspot-video,
.hotspot-img {
  height: 45vh;
  width: auto;
  margin: 0 auto;
  box-shadow: $c-black-50 0 0 24px;

  @include breakpoint('md-only') {
    height: 30vh;
  }

  @include breakpoint('sm-and-down') {
    height: 30vh;
  }
  @include breakpoint('xs-only') {
    height: auto;
    width: 100%;
    margin-bottom: 5px;
  }

  &.bottle {
    box-shadow: none;
    @include breakpoint('xs-only') {
      height: auto;
      width: 80%;
      margin-bottom: 5px;
    }
  }
}

.hotspot-video {
  height: 50vh;
  width: calc((50vh / 9) * 16);

  @include breakpoint('sm-and-down') {
    width: auto;
  }

  @include breakpoint('xs-only') {
    height: auto;
    width: 80vw;
    box-shadow: none;

    ::v-deep .plyr {
      height: 75vw;
      width: 75vw;
    }
  }
}

svg {
  width: 18px;
  height: 18px;
}

.slide-paragraph {
  width: 80%;
  margin: auto;

  @include breakpoint('xs-only') {
    width: 95%;
  }
}

.slide-paragraph p {
  @include breakpoint('xs-only') {
    margin-bottom: 0;
  }
}

.cta-button {
  width: auto;
  margin: 10px auto;
  background-color: $c-primary;
  box-shadow: $c-black-50 0 0 12px;
  padding: 8px 22px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.4s ease-in-out;

  @include breakpoint('sm-and-down') {
    width: 100%;
    padding: 6px 10px;
    font-size: 14px;
  }

  @include breakpoint('xs-only') {
    padding: 10px 10px 6px !important;
  }

  &:hover {
    background-color: $c-white;
    color: $c-primary;
  }

}
</style>
