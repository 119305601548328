<template>
  <div class="main-player">
    <div class="video-container">

      <vue-plyr
        ref="plyr"
        :options="playerOptions"
        :emit="playerEmits"
        @ready="onVideoReady"
        @ended="onVideoFinished"
        @timeupdate="onTimeUpdate"
        class="player absolute-center"
        :style="{width: videoW + 'px', height: videoH + 'px', transform: 'translate(' + videoX + 'px ,' + videoY + 'px)'}"
      >
        <video :src="'/media/' + videoId" playsinline crossorigin></video>
      </vue-plyr>

      <VideoControl
        v-show="false"
        :player="player"
      />

      <transition name="fade">
        <div v-show="showLottieLoader" class="absolute-bottom text-center">
          <Lottie
            :options="{ animationData:LoaderAnimation, loop:true }"
            :height="100"
            :width="100"
          />
          <p class="text-uppercase mt-4 mb-0">Loading</p>
        </div>
      </transition>

      <transition :name="$root.platform.mobile ? 'fade' : 'fade-slide'">
        <div
          v-show="videoSelection.show"
          :style="{width: videoW + 'px', height: videoH + 'px', transform: 'translate(' + videoX + 'px ,' + videoY + 'px)'}"
          class="absolute-center bg-video-selection"
        >
          <VideoSelection @onVideoSelected="toogleVideoSelection"/>
        </div>
      </transition>

      <transition name="fade">
        <p v-show="videoSelection.show" class="tutorial" style="transition-delay: 0.4s;">
          {{ $root.allTranslations[$route.params.language].hotspotTipCopy }}
        </p>
      </transition>

      <div
        v-if="videoSequencies.showMacroHotspot"
        :style="{width: videoW + 'px', height: videoH + 'px', transform: 'translate(' + videoX + 'px ,' + videoY + 'px)'}"
        class="absolute-center"
      >
        <MacroHotspotContent
          v-show="videoSequencies.showMacroHotspot"
          :content="videoSequencies.content"
          @onHotspotClick="onHotspotClick"
        />
      </div>

      <transition name="fade">
        <MacroHotspotOverlay
          v-if="videoSequencies.showMacroHotspot"
          :sequence="videoSequencies"
          @onContinue="onContinue"
        />
      </transition>

      <transition name="fade">
        <HotspotCarousel
          v-if="videoSequencies.showCarousel"
          :content="videoSequencies.content"
          @onCloseContinue="onHotspotClose"
          @onPlayPauseAudio="$emit('onPlayPauseAudio')"
        />
      </transition>

      <transition name="fade">
        <EndVideoContent
          v-show="showEndVideoContent"
        />
      </transition>

    </div>
  </div>
</template>

<script>
import Lottie from 'vue-lottie'
import LoaderAnimation from '@/assets/bodymovin/mutti-loader-red'

import MacroHotspotOverlay from './MacroHotspotOverlay.vue'
import { Viewport } from '@monogrid/vue-lib'
import { RAF } from '@monogrid/js-utils'
import VideoControl from '../molecules/VideoControl.vue'
import VideoSelection from './VideoSelection.vue'
import MacroHotspotContent from './MacroHotspotContent.vue'
import HotspotCarousel from './HotspotCarousel.vue'
import EndVideoContent from './EndVideoContent.vue'

export default {
  name: 'MainVideo',
  mixins: [Viewport],

  components: {
    Lottie,
    VideoControl,
    VideoSelection,
    MacroHotspotContent,
    HotspotCarousel,
    MacroHotspotOverlay,
    EndVideoContent
  },

  data () {
    return {
      showLottieLoader: false,
      LoaderAnimation,
      player: null,
      playerOptions: {
        storage: { enabled: false, key: 'plyr' },
        fullscreen: { enabled: false },
        hideControls: true,
        controls: [],
        clickToPlay: false,
        playsinline: true
      },
      playerEmits: [
        'ready',
        'timeupdate',
        'ended',
        'pause',
        'playing',
        'seeking',
        'seeked'
      ],
      // showLastTip: false,
      videoX: 0,
      videoY: 0,
      videoW: 0,
      videoH: 0,
      videoId: '',
      // Video selection
      videoSelection: {
        seen: false,
        show: false
      },
      // Video sequencies
      videoSequencies: {
        index: 0,
        opened: false,
        show: false,
        showCarousel: false,
        showMacroHotspot: false,
        content: {},
        nextcontent: {}
      },
      // End video
      showEndVideoContent: false
    }
  },

  mounted () {
    this.videoId = (this.$root.platform.mobile && this.$root.siteSetting.videoMobile) ? this.$root.siteSetting.videoMobile.id : this.$root.siteSetting.videoFile.id
    if (this.$refs.plyr) {
      this.player = this.$refs.plyr.player
    }

    this.dragging = false
    this.dragX = 0
    this.dragY = 0
    this.limitX = { min: 0, max: 0 }
    this.limitY = { min: 0, max: 0 }

    this.updateVideoSize()
    // this.addListener()

    this.targetX = this.videoX
    this.targetY = this.videoY
    RAF.add(this.updateBuffer)
  },

  watch: {
    viewPort (newValue, oldValue) {
      this.updateVideoSize()
    }
  },

  methods: {
    addListener () {
      if (!this.$root.platform.mobile) {
        window.addEventListener('mousedown', this.onMouseDown)
        window.addEventListener('mousemove', this.onMouseMove)
        window.addEventListener('mouseup', this.onMouseUp)
      } else {
        document.addEventListener('touchstart', this.onMouseDown, false)
        document.addEventListener('touchmove', this.onMouseMove, false)
        document.addEventListener('touchend', this.onMouseUp, false)
      }
    },

    removeListener () {
      if (!this.$root.platform.mobile) {
        window.removeEventListener('mousedown', this.onMouseDown)
        window.removeEventListener('mousemove', this.onMouseMove)
        window.removeEventListener('mouseup', this.onMouseUp)
      } else {
        document.removeEventListener('touchstart', this.onMouseDown, false)
        document.removeEventListener('touchmove', this.onMouseMove, false)
        document.removeEventListener('touchend', this.onMouseUp, false)
      }
    },

    onMouseDown (e) {
      this.dragging = true
      this.dragX = this.$root.platform.mobile ? e.touches[0].clientX : e.clientX
      this.dragY = this.$root.platform.mobile ? e.touches[0].clientY : e.clientY
      RAF.add(this.update)
    },
    onMouseUp () {
      this.dragging = false
      RAF.remove(this.update)
    },
    onMouseMove (e) {
      if (this.dragging) {
        const cliX = this.$root.platform.mobile ? e.touches[0].clientX : e.clientX
        const cliY = this.$root.platform.mobile ? e.touches[0].clientY : e.clientY

        const diffX = (this.dragX - cliX) * 20
        const diffY = (this.dragY - cliY) * 20
        this.targetX = this.videoX - diffX
        this.targetY = this.videoY - diffY

        if (this.targetX < this.limitX.min) {
          this.targetX = this.limitX.min
        } else if (this.targetX > this.limitX.max) {
          this.targetX = this.limitX.max
        }

        if (this.targetY < this.limitY.min) {
          this.targetY = this.limitY.min
        } else if (this.targetY > this.limitY.max) {
          this.targetY = this.limitY.max
        }

        this.dragX = this.$root.platform.mobile ? e.touches[0].clientX : e.clientX
        this.dragY = this.$root.platform.mobile ? e.touches[0].clientY : e.clientY
      }
    },

    updateBuffer () {
      if (this.$refs.plyr) {
        if (this.$refs.plyr.$el.firstChild.classList) {
          const classlist = this.$refs.plyr.$el.firstChild.classList
          for (let i = 0; i < classlist.length; i++) {
            const element = classlist[i]
            if (element === 'plyr--loading') {
              // console.log('buffering')
              this.showLottieLoader = true
            } else {
              // console.log('Not buffering')
              this.showLottieLoader = false
            }
          }
        }
      }
    },
    update () {
      this.videoX += (this.targetX - this.videoX) / 20
      this.videoY += (this.targetY - this.videoY) / 20
    },

    updateVideoSize () {
      if (this.$root.platform.mobile && this.$root.siteSetting.videoMobile) {
        const ratio = Math.max(this.viewPort.width / 1080, this.viewPort.height / 1350) * 1
        this.videoW = 1080 * ratio
        this.videoH = 1350 * ratio
      } else {
        const ratio = Math.max(this.viewPort.width / 1920, this.viewPort.height / 1080) * 1
        this.videoW = 1920 * ratio
        this.videoH = 1080 * ratio
      }

      this.limitX.min = (this.videoW * -0.5) - ((this.videoW - this.viewPort.width) / 2)
      this.limitX.max = (this.videoW * -0.5) + ((this.videoW - this.viewPort.width) / 2)

      this.limitY.min = (this.videoH * -0.5) - ((this.videoH - this.viewPort.height) / 2)
      this.limitY.max = (this.videoH * -0.5) + ((this.videoH - this.viewPort.height) / 2)

      // console.log(this.limitX, this.limitY)

      this.videoX = this.videoW * -0.5
      this.videoY = this.videoH * -0.5
    },

    onVideoReady () {
      if (this.$refs.plyr) {
        this.player = this.$refs.plyr.player

        if (this.$route.name === 'Video') {
          this.player.play()
        }
      }
    },

    onTimeUpdate (progress) {
      /* Show Video Selection */
      if (progress.detail.plyr.currentTime >= this.$root.siteSetting.timeVideoSelection && !this.videoSelection.seen) {
        // console.log('Show video selection', progress.detail.plyr.currentTime)
        this.toogleVideoSelection()
      }

      /* Show Video Sequencies */
      const currentSequence = this.$root.siteSetting.videoHotsposts[this.videoSequencies.index]
      if (currentSequence && progress.detail.plyr.currentTime >= currentSequence.time && !this.videoSequencies.show) {
        // console.log('Show hotspot', progress.detail.plyr.currentTime, this.$root.siteSetting.videoHotsposts[this.videoSequencies.index])
        this.showMacroHotspot(this.videoSequencies.index, this.$root.siteSetting.videoHotsposts[this.videoSequencies.index])
      }
    },

    onVideoFinished () {
      this.showEndVideoContent = true
      this.$emit('onVideoFinished')
    },

    playStopVideo () {
      this.player.playing
        ? this.player.pause()
        : this.player.play()
    },

    toogleVideoSelection () {
      if (!this.videoSelection.seen) {
        this.videoSelection.seen = true
        this.videoSelection.show = true
      } else {
        this.videoSelection.show = false
      }
      this.playStopVideo()
    },

    onHotspotClick () {
      this.videoSequencies.showMacroHotspot = false
      this.videoSequencies.showCarousel = true
      this.videoSequencies.opened = true
    },

    onHotspotClose (value) {
      this.videoSequencies.showCarousel = false
      this.videoSequencies.showMacroHotspot = true

      if (value === 'Passata') {
        this.videoSequencies.showMacroHotspot = false
        this.playStopVideo()
      }
    },

    onContinue () {
      this.videoSequencies.showMacroHotspot = false
      this.videoSequencies.showCarousel = false
      this.playStopVideo()
    },

    showMacroHotspot (index, content) {
      this.videoSequencies.showMacroHotspot = true
      this.videoSequencies.opened = false
      this.videoSequencies.content = content
      this.videoSequencies.index++

      this.playStopVideo()
    }
  }
}
</script>

<style lang="scss" scoped>
.main-player {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bg-video-selection {
  @include breakpoint('xs-only') {
    background-color: $c-black-30;
  }
}

.tutorial {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -65%);
  width: 85%;
  margin: 0 auto;
  text-align: center;

  @include breakpoint('xs-only') {
    top: 35%;
  }
}
</style>
