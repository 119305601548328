<template>
  <div>
    <AudioPlayer
      v-show="showVideo"
      ref="audioPlayer"
      :sources="['/media/' + $root.siteSetting.bgAudio.id]"
      :loop="true"
    />

    <MainVideo
      v-show="showVideo"
      @onPlayPauseAudio="onPlayPauseAudio"
      @onVideoFinished="onVideoFinished"
    />

    <transition name="fade">
      <div class="over" v-show="!showVideo && imageLoaded">
        <div class="bg-image fullscreen" :style="{'background-image' : 'url(/media/'+ $root.siteSetting.homepageBgImage.id + ')'}"></div>
        <img
          class="hide"
          :src="'/media/' + $root.siteSetting.homepageBgImage.id"
          @load="imageLoaded=true"
        />
        <div class="bg-cover"></div>
        <transition name="homepage">
          <div v-if="imageLoaded" class="homepage-content absolute-center text-center">
            <h1 class="brushaff mb-12">
              <span
                v-for="(char,i) in $root.allTranslations[$route.params.language].startExpTitle"
                :style="{'animation-delay': (0.5 + (i * 0.1)) + 's'}"
                :key="'char'+i">
                {{ (char === ' ') ? '&nbsp;' : char }}
              </span>
            </h1>
            <MarkdownBlock
              class="description"
              v-if="$root.allTranslations[$route.params.language].startExpDescription"
              :inline="false"
              linkify
              tag="div"
              :text="$root.allTranslations[$route.params.language].startExpDescription"
            />
            <div class="enter-button pointer mt-16" @click="onEnter">
              {{ $root.allTranslations[$route.params.language].startExpCopy }}
            </div>
          </div>
        </transition>
      </div>
    </transition>

  </div>
</template>

<script>
import AudioPlayer from '@/organisms/AudioPlayer.vue'
import MainVideo from '../organisms/MainVideo.vue'

import { Viewport, MarkdownBlock } from '@monogrid/vue-lib'

export default {
  name: 'Home',

  mixins: [Viewport],

  components: {
    MarkdownBlock, AudioPlayer, MainVideo
  },

  mounted () {
    this.audioPlayer = this.$refs.audioPlayer
  },

  data () {
    return {
      imageLoaded: false,
      showVideo: false,
      audioPlayer: null
    }
  },
  methods: {
    onEnter () {
      this.showVideo = true
      this.$events.emit('onPlayVideo')
      setTimeout(() => {
        this.audioPlayer.play()
      }, 50)
    },
    onPlayPauseAudio () {
      this.audioPlayer.togglePlayback()
    },
    onVideoFinished () {
      /* Audio fade out */
      const fadeOut = setInterval(() => {
        const vol = this.audioPlayer.volume

        if (vol === 0) {
          clearInterval(fadeOut)
          this.audioPlayer.stop()
        }

        this.audioPlayer.setVolume(vol - 0.15)
      }, 150)
    }
  },

  metaInfo: function () {
    return {
      // home page browser title
      title: this.$root.siteSetting.title
    }
  }
}
</script>

<style scoped lang="scss">
.hide {
  display: none;
}

.bg-image {
  background-size: cover;
  background-position: center;
}

.bg-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $c-black-50;
}

.enter-button {
  background-color: $c-primary;
  box-shadow: $c-black-50 0 0 12px;
  width: 280px;
  margin: 0 auto;
  padding: 8px 22px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.4s ease-in-out;

  @include breakpoint('sm-and-down') {
    max-width: 100%;
    padding: 10px 10px 6px !important;
    font-size: 14px;
  }

  @include breakpoint('xs-only') {
    padding: 10px 10px 6px !important;
  }

  &:hover {
    background-color: $c-white;
    color: $c-primary;
  }
}

.homepage-content {
  transition-delay: 0.5s;
  width: 80%;
}

.homepage-leave-active,
.homepage-enter-active {
  transition: opacity 3s $ease-out-quint;

  .description,
  .enter-button,
  h1 {
    transition: opacity 1s $ease-out-quint, transform 1s $ease-out-quint;
  }

  h1 {
    transition-delay: 0.5s;
  }

  .description {
    transition-delay: 1.25s;
  }

  .enter-button {
    transition-delay: 1.35s;
  }
}

.homepage-enter,
.homepage-leave-to {
  .description,
  .enter-button,
  h1 {
    opacity: 0;
    transform: translateY(40px);
  }
}
</style>
